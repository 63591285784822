<template lang="pug">
    .container.max-w-800
        user-post-page
</template>

<script>
//Components
import UserPostPage from '@/components/views/user-posts/user-post-page';

export default {
    name: "UserPostPage",
    components: {
        'user-post-page': UserPostPage,
    },
}
</script>