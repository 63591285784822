<template lang="pug">
    .flex.flex-col
        .flex.items-center
            .text-sm.mr-1 
                span &#8592;
            .text-sm.text-accent.cursor-pointer(@click="$router.push('/user-posts/list')")
                span.underline к списку
            .text-sm.ml-1
                span / 
                span {{ `сообщение от ${user_posts.lastname} ${user_posts.firstname} ${user_posts.patronymic}` }}
        section.p-4
            section.mb-3
                .flex
                    .text-md.font-bold.mr-2 Фамилия: 
                    .text.md.font-light {{ user_posts.lastname }}
                .flex
                    .text-md.font-bold.mr-2 Имя: 
                    .text.md.font-light {{ user_posts.firstname }}
                .flex
                    .text-md.font-bold.mr-2 Отчество: 
                    .text.md.font-light {{ user_posts.patronymic }}
                .flex
                    .text-md.font-bold.mr-2 Год рождения: 
                    .text.md.font-light {{ user_posts.date_of_birth }}
            section.mb-6
                .flex
                    .text-md.font-bold.mr-2 Город: 
                    .text.md.font-light {{ user_posts.city }}    
                .flex
                    .text-md.font-bold.mr-2 Телефон: 
                    .text.md.font-light {{ user_posts.phone }}
            section.mb-6
                template(v-if="user_posts.title")
                    div.mb-3
                        .text-md.font-bold Заголовок
                        .text-md.font-light {{ user_posts.title }}
                div
                    .text-md.font-bold Текст
                    .text-md.font-light {{ user_posts.text }}
            section.mb-6
                .text-md.font-bold.mb-2 Прикрепленные файлы:
                template(v-for="(file, index) in user_posts.attachment")
                    ul
                        li.mb-2
                            a(:href="`${base_url}/${file.path}`" target="_blank" class="hover:underline flex") 
                                img.mr-1(:src="selectIcon({ file_type: file.type })")
                                span Файл: {{ index+1 }}
            
            section
                .text-md.font-bold Результат проверки:
                template(v-for="(like, index) in user_posts.likes")
                    ul
                        //- li.mb-2 {{ like }}

</template>

<script>
//Store
import { UserPostsNameSpace, UserPostsActionTypes, UserPostsGetterTypes } from '@/store/user-posts/types';
//Helpers
import { selectIcon } from '@/helpers/select-icon';

export default {
    name: "UserPostPage",
    computed: {
        user_posts() {
            return this.$store.getters[`${UserPostsNameSpace}/${UserPostsGetterTypes.GetUserPosts}`][0]
        },
        base_url() {
            return process.env.VUE_APP_BASE_URL
        },
    },
    mounted() {
        this.onLoad(this.$route.params)
    },
    methods: {
        onLoad({ id }) {            
            this.$store.dispatch(`${UserPostsNameSpace}/${UserPostsActionTypes.GetUserPosts}`, { params: {_id: id} })
        },
        selectIcon({ file_type }) {
            return selectIcon({ file_type })
        }
    }
}
</script>